import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import RecipeCard, { Recipe } from "./RecipeCard";
import backendUrl from "./config";

const PublicRecipeGallery: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [page, setPage] = useState(0);
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchRecipes = async () => {
      const source = axios.CancelToken.source();
      try {
        const response = await axios.get<Recipe[]>(
          `${backendUrl}/public/recipes`,
          {
            params: { page },
            cancelToken: source.token,
          }
        );

        setRecipes((prevRecipes) => [...prevRecipes, ...response.data]);
        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching recipes:", error);
        }
      }
      return () => {
        source.cancel("Component unmounted, canceling the request.");
      };
    };
    const currentBoundaryRef = bottomBoundaryRef.current;
    const scrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchRecipes();
        }
      });
    });

    if (currentBoundaryRef) {
      scrollObserver.observe(currentBoundaryRef);
    }

    return () => {
      if (currentBoundaryRef) {
        scrollObserver.unobserve(currentBoundaryRef);
      }
    };
  }, [bottomBoundaryRef, page]);

  return (
    <Grid container spacing={3}>
      {recipes.map((recipe) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={recipe.id}
          sx={{ paddingTop: 2, paddingBottom: 2 }}
        >
          <RecipeCard recipe={recipe} />
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        ref={bottomBoundaryRef}
        sx={{ paddingTop: 2, paddingBottom: 2 }}
      ></Grid>
    </Grid>
  );
};

export default PublicRecipeGallery;
