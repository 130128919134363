import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
} from "@mui/material";

const Pricing = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 4,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Pricing Plans
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 4,
        }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="Free Trial" subheader="Free" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              - Limited number of free recipes per day
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Access to basic recipe generation and sharing features
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="Basic Plan" subheader="$5/month" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              - Limited number of recipes per month
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Recipe saving and sharing features
            </Typography>
            <Button variant="contained" color="primary">
              Subscribe
            </Button>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="Standard Plan" subheader="$10/month" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              - Unlimited recipe generation
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Access to exclusive recipe categories
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Priority customer support
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Request custom recipe modifications
            </Typography>
            <Button variant="contained" color="primary">
              Subscribe
            </Button>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="Pro Plan" subheader="$20/month" />
          <CardContent>
            <Typography variant="body1" gutterBottom>
              - Unlimited recipe generation
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Access to exclusive recipe categories
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Priority customer support
            </Typography>
            <Typography variant="body1" gutterBottom>
              - Request custom recipe modifications
            </Typography>
            <Button variant="contained" color="primary">
              Subscribe
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Typography variant="h4" gutterBottom>
        One-time Purchase Options
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 4,
          marginBottom: 4,
        }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="10 Recipe Pack" subheader="$2.99" />
          <CardContent>
            <Button variant="contained" color="primary">
              Buy Now
            </Button>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="25 Recipe Pack" subheader="$6.99" />
          <CardContent>
            <Button variant="contained" color="primary">
              Buy Now
            </Button>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title="50 Recipe Pack" subheader="$12.99" />
          <CardContent>
            <Button variant="contained" color="primary">
              Buy Now
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Pricing;
