import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import RecipeCard, { Recipe } from "./RecipeCard";
import backendUrl from "./config";

const RecipeGallery: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        console.log("access token get recipe", accessToken);

        const response = await axios.get<string[]>(
          `${backendUrl}/user/recipes/uuids`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const recipeUuids = response.data;

        const recipePromises = recipeUuids.map((uuid) =>
          axios.get<Recipe>(`${backendUrl}/recipe/${uuid}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        );
        const recipeResponses = await Promise.all(recipePromises);

        setRecipes(recipeResponses.map((res) => res.data));
      } catch (error) {
        console.error("Error fetching recipe UUIDs:", error);
      }
    };

    fetchRecipes();
  }, []);

  return (
    <Grid container spacing={3}>
      {recipes.map((recipe) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={recipe.id}
          sx={{ paddingTop: 2, paddingBottom: 2 }}
        >
          <RecipeCard recipe={recipe} />
        </Grid>
      ))}
    </Grid>
  );
};

export default RecipeGallery;
