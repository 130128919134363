import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import CreateRecipe from "./components/CreateRecipe";
import RecipeGallery from "./components/RecipeGallery";
import PrivateRoute from "./components/PrivateRoute";
import RecipeView from "./components/RecipeView";
import Pricing from "./components/Pricing";
import AdminRecipeGallery from "./components/AdminRecipeGallery";
import CustomAppBar from "./components/CustomAppBar";

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = React.useState(
    !!localStorage.getItem("access_token")
  );

  return (
    <Router>
      <CustomAppBar
        authenticated={authenticated}
        setAuthenticated={setAuthenticated}
      />
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              authenticated={authenticated}
              setAuthenticated={setAuthenticated}
            />
          }
        />
        <Route path="/pricing" element={<Pricing />} />
        <Route
          path="/create-recipe"
          element={
            <PrivateRoute isAuthenticated={authenticated}>
              <CreateRecipe />
            </PrivateRoute>
          }
        />
        <Route
          path="/recipe-gallery"
          element={
            <PrivateRoute isAuthenticated={authenticated}>
              <RecipeGallery />
            </PrivateRoute>
          }
        />
        <Route
          path="/recipe/:recipeId"
          element={
            <PrivateRoute isAuthenticated={authenticated}>
              <RecipeView />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute isAuthenticated={authenticated}>
              <AdminRecipeGallery />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
