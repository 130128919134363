import React, { ComponentType } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
  CardActions,
  IconButton,
} from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  RedditIcon,
} from "react-share";
import PublicToggle from "./PublicToggle";
import backendUrl from "./config";

export interface Recipe {
  title: string;
  ingredients: string[];
  cooking_time: string;
  servings: number | string;
  instructions: string[];
  reasoning: string;
  image: string;
  id: string;
  public: boolean;
}

interface RecipeCardProps {
  recipe: Partial<Recipe>;
}

const RecipeCard: React.FC<RecipeCardProps> = ({ recipe }) => {
  const baseUrl = window.location.origin;
  const shareUrl = `${baseUrl}/recipe/${recipe.id}`;

  return (
    <Card
      sx={{
        marginTop: 4,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      {recipe.image ? (
        <CardMedia
          component="img"
          width="100%"
          image={recipe.image}
          alt="Recipe image"
          sx={{
            objectFit: "cover",
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "200px",
            bgcolor: "grey.300",
          }}
        >
          <CircularProgress />
          <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
            Loading fun recipe image...
          </Typography>
        </Box>
      )}
      <CardActions disableSpacing>
        <FacebookShareButton url={shareUrl} quote={recipe.title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={recipe.title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <EmailShareButton url={shareUrl} title={recipe.title}>
          <EmailIcon size={32} round />
        </EmailShareButton>
        <RedditShareButton url={shareUrl} title={recipe.title}>
          <RedditIcon size={32} round />
        </RedditShareButton>
      </CardActions>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {recipe.title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {recipe.reasoning}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Cooking Time: {recipe.cooking_time}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Servings: {recipe.servings}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Ingredients:
        </Typography>
        <ul>
          {recipe.ingredients?.map((ingredient, index) => (
            <li key={index}>{ingredient}</li>
          ))}
        </ul>
        <Typography variant="h6" gutterBottom>
          Instructions:
        </Typography>
        <ol>
          {recipe.instructions?.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ol>
      </CardContent>
    </Card>
  );
};

export default RecipeCard;

const withAdminFeatures = <P extends RecipeCardProps>(
  WrappedComponent: ComponentType<P>,
  isAdmin: boolean
): React.FC<P> => {
  const WithAdminFeatures: React.FC<P> = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleDelete = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        await axios.delete(`${backendUrl}/recipe/${props.recipe.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        window.location.reload();
      } catch (error) {
        console.error("Error deleting recipe:", error);
      }
    };

    const handleConfirmDelete = () => {
      handleDelete();
      handleClose();
    };

    return (
      <>
        {isAdmin && (
          <>
            <IconButton
              aria-label="delete"
              onClick={handleClickOpen}
              edge="end"
              color="inherit"
            >
              <DeleteIcon />
            </IconButton>
            <PublicToggle recipe={props.recipe} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Recipe Deletion"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this recipe? This action
                  cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <WrappedComponent {...props} />
      </>
    );
  };

  return WithAdminFeatures;
};

export const AdminRecipeCard = withAdminFeatures(RecipeCard, true);
