import React, { useState, useEffect, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate, Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import backendUrl from "./config";

interface CustomAppBarProps {
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomAppBar: React.FC<CustomAppBarProps> = ({
  authenticated,
  setAuthenticated,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setUserProfile(null);
    setAuthenticated(false);
    setIsAdmin(false);
    handleClose();
    navigate("/");
  };

  const checkAdminRole = useCallback(async () => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) return;

    // Check if the user has the admin role using access_token
    const response = await fetch(`${backendUrl}/user/is_admin`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (response.status === 200) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [backendUrl]);

  useEffect(() => {
    if (authenticated) {
      checkAdminRole();

      setUserProfile(jwtDecode(localStorage.getItem("access_token") || "{}"));
    }
  }, [authenticated, checkAdminRole]);

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white" }}
      style={{ zIndex: 1200 }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo192.png`}
            alt="Logo"
            height="30"
          />
        </IconButton>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "inherit", flexGrow: 1 }}
        >
          <Typography variant="h6" sx={{ color: "black" }}>
            Gourmet AI
          </Typography>
        </Link>
        {/* <Button
          color="inherit"
          component={Link}
          to="/pricing"
          sx={{ color: "black" }}
        >
          Pricing
        </Button> */}
        {authenticated && isAdmin ? (
          <Button
            color="inherit"
            component={Link}
            to="/admin"
            sx={{ color: "black" }}
          >
            Admin Console
          </Button>
        ) : null}
        {authenticated ? (
          <>
            <Avatar
              src={userProfile?.picture} // Add this line to set the user's avatar
              sx={{
                bgcolor: userProfile?.picture ? undefined : "secondary.main",
              }}
              onClick={handleClick}
            >
              {!userProfile?.picture && userProfile?.name?.[0]}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
              if (typeof credentialResponse.credential == "string") {
                localStorage.setItem(
                  "access_token",
                  credentialResponse.credential
                );
                setAuthenticated(true);
              }
            }}
            onError={() => {
              console.log("Login Failed");
            }}
            useOneTap
            auto_select
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
