import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import RecipeCard, { Recipe } from "./RecipeCard";
import axios from "axios";
import backendUrl from "./config";

const RecipeView: React.FC = () => {
  const { recipeId } = useParams<{ recipeId: string }>();
  const [recipe, setRecipe] = useState<Recipe | null>(null);

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios
          .get<Recipe>(`${backendUrl}/recipe/${recipeId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then();
        setRecipe(response.data);
      } catch (error) {
        console.error("Error fetching recipe:", error);
      }
    };

    fetchRecipe();
  }, [recipeId]);

  return <Container>{recipe && <RecipeCard recipe={recipe} />}</Container>;
};

export default RecipeView;
