import React, { useState, useCallback } from "react";
import {
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Box,
} from "@mui/material";
import RecipeCard, { Recipe } from "./RecipeCard";
import backendUrl from "./config";

export function completeJson(
  jsonString: string,
  recipe: Partial<Recipe>
): Partial<Recipe> {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.log(jsonString);
      // check if https://storage.googleapis.com/recipe-images-gourmet/recipe_image is in the string
      if (
        jsonString.indexOf(
          "https://storage.googleapis.com/recipe-images-gourmet/recipe_image"
        ) !== -1
      ) {
        // get that string and everything after it
        const startIndex = jsonString.indexOf(
          "https://storage.googleapis.com/recipe-images-gourmet/recipe_image"
        );
        const endIndex = jsonString.length;
        const substring = jsonString.substring(startIndex, endIndex);

        // try to parse the recipe before the image
        const recipeString = jsonString.substring(0, startIndex);
        try {
          recipe = JSON.parse(recipeString) as Partial<Recipe>;
          recipe["image"] = substring;
        } catch (e) {
          if (e instanceof SyntaxError) {
            recipe["image"] = substring;
            return recipe;
          }
        }

        return recipe;
      }
      if (jsonString.trim() === "") {
        jsonString = "{}";
      } else {
        let stack = [];
        let quote = false;

        for (const char of jsonString) {
          if (char === '"' && stack[stack.length - 1] !== "\\") {
            quote = !quote;
          } else if (!quote) {
            if (char === "{" || char === "[") {
              stack.push(char);
            } else if (char === "}" && stack[stack.length - 1] === "{") {
              stack.pop();
            } else if (char === "]" && stack[stack.length - 1] === "[") {
              stack.pop();
            }
          }
        }

        for (let i = stack.length - 1; i >= 0; i--) {
          if (stack[i] === "{") {
            jsonString += quote ? '"}' : "}";
          } else if (stack[i] === "[") {
            jsonString += quote ? '"]' : "]";
          }
          quote = false;
        }
      }
    }
  }

  try {
    JSON.parse(jsonString);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return recipe;
    }
  }

  return JSON.parse(jsonString) as Partial<Recipe>;
}

export function createPartialRecipeParser() {
  let buffer = "";
  let recipe: Partial<Recipe> = {};

  return function parseChunk(chunk: string): Partial<Recipe> {
    buffer += chunk;

    recipe = completeJson(buffer, recipe);

    return recipe;
  };
}

const CreateRecipe: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [recipe, setRecipe] = useState<Partial<Recipe> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [startGeneration, setStartGeneration] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const generateRecipe = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      // clear current recipe
      setRecipe(null);
      const accessToken = localStorage.getItem("access_token");

      const promptData = { prompt };
      const res = await fetch(`${backendUrl}/recipe/stream/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(promptData),
      });

      const reader = res?.body?.getReader();

      if (!reader) {
        throw new Error("No reader");
      }

      const recipeParser = createPartialRecipeParser();

      const readAndUpdate = async () => {
        const { value, done } = await reader.read();
        setIsLoading(false);
        if (done) {
          return;
        }

        const chunk = new TextDecoder().decode(value);
        const partialRecipe = recipeParser(chunk);
        setRecipe((prevRecipe) => ({
          ...prevRecipe,
          ...partialRecipe,
        }));
        await readAndUpdate();
      };

      readAndUpdate();
    } catch (error) {
      console.error("Fetch error:", error);
      setError(
        "An error occurred while generating the recipe. Please try again."
      );
      setIsLoading(false);
    }
  }, [prompt]);

  React.useEffect(() => {
    if (startGeneration) {
      generateRecipe();
      setStartGeneration(false);
    }
  }, [startGeneration, prompt, generateRecipe]);

  return (
    <Container maxWidth="md">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ paddingTop: 2, paddingBottom: 2 }}
      >
        Create Recipe
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Generate recipes based on text descriptions. Be as generic or specific
        as you want!
      </Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                borderRadius: 1,
                overflow: "hidden",
                paddingTop: 2,
              }}
            >
              <TextField
                fullWidth
                label="Create a recipe for..."
                variant="outlined"
                value={prompt}
                onChange={handleChange}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!prompt}
                onClick={() => setStartGeneration(true)}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              >
                Generate
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {isLoading ? (
        <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
          <CircularProgress />
        </Grid>
      ) : (
        recipe && <RecipeCard recipe={recipe} />
      )}
    </Container>
  );
};

export default CreateRecipe;
