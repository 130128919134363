import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#2c3e50",
      light: "#34495e",
      dark: "#1c2833",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#1abc9c",
      light: "#16a085",
      dark: "#0e6655",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#777777",
    },
  },
  typography: {
    fontFamily: "Lato, Helvetica, Arial, sans-serif",
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="947046022823-bku9sgqhlppiqa93fnkfiod438eequgg.apps.googleusercontent.com">
      {/* <BrowserRouter> */}
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      {/* </BrowserRouter> */}
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
