import React, { useEffect } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PublicRecipeGallery from "./PublicRecipeGallery";

interface HomePageProps {
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const HomePage: React.FC<HomePageProps> = ({
  authenticated,
  setAuthenticated,
}) => {
  const navigate = useNavigate();

  const handleCreateRecipe = () => {
    navigate("/create-recipe");
  };

  const handleViewRecipeGallery = () => {
    navigate("/recipe-gallery");
  };

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "access_token") {
        setAuthenticated(!!localStorage.getItem("access_token"));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [authenticated, setAuthenticated]);

  return (
    <div>
      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{ paddingTop: 2 }}
            >
              Welcome to Gourmet AI
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              sx={{ paddingBottom: 2 }}
            >
              Create recipes based on text descriptions using AI.
            </Typography>
          </Grid>
        </Grid>
        {authenticated && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateRecipe}
              >
                Create New Recipe
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleViewRecipeGallery}
              >
                View Recipe Gallery
              </Button>
            </Grid>
          </Grid>
        )}
        {/* Add any content that should be visible to unauthenticated users */}
        <PublicRecipeGallery />
        <footer>
          <Typography
            variant="body2"
            align="center"
            gutterBottom
            sx={{ paddingTop: 2, paddingBottom: 2 }}
          >
            © 2023 Gourmet AI. All Rights Reserved.
          </Typography>
        </footer>
      </Container>
    </div>
  );
};

export default HomePage;
