import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateProps {
  isAuthenticated: any;
  children: React.ReactNode;
}

const Private: React.FC<PrivateProps> = ({ isAuthenticated, children }) => {
  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
};

export default Private;