import React, { useState } from "react";
import { Switch } from "@mui/material";
import axios from "axios";
import { Recipe } from "./RecipeCard";
import backendUrl from "./config";

interface PublicToggleProps {
  recipe: Partial<Recipe>;
}

const PublicToggle: React.FC<PublicToggleProps> = ({ recipe }) => {
  const [isPublic, setIsPublic] = useState(recipe.public);
  const accessToken = localStorage.getItem("access_token");

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPublicStatus = event.target.checked;

    try {
      await axios.patch(
        `${backendUrl}/recipe/${recipe.id}/public`,
        { public: newPublicStatus },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Update local state only if the request is successful
      setIsPublic(newPublicStatus);
    } catch (error) {
      console.error("Error updating recipe public status:", error);
    }
  };

  return (
    <Switch
      checked={isPublic}
      onChange={handleChange}
      inputProps={{ "aria-label": "Public recipe toggle" }}
    />
  );
};

export default PublicToggle;
